// Modals

// Padding applied to the modal body
$modal-inner-padding: rem(remove-units($grid-gutter-width)) !default;

$modal-dialog-margin: $spacer * 2 !default;
$modal-dialog-margin-y-sm-up: $spacer * 2 !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($border-color-light, 0.3) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $background-gray-dark-1 !default;
$modal-backdrop-opacity: 0.7 !default;
$modal-header-border-color: rgba($border-color-light, 0.3) !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: $spacer !default;

$modal-lg: 1880px !default;
$modal-md: 838px !default;
$modal-sm: 600px !default;

$modal-transition: transform 0.3s ease-out !default;
