@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  option {
    @include themify($themes) {
      background-color: themed('color-background');
    }
  }
}

div.rs__control {
  border-color: $input-border-color;
  border-radius: $border-radius;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: $input-font-size;
  height: auto;
  min-height: 30px;
  min-width: 122px;
  width: 100%;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    min-height: 30px;
  }

  &:hover {
    border-color: $input-border-color;
  }

  &--is-focused {
    @include focus-shadow($success);
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0.1px;
    border-bottom-right-radius: 0.1px;
  }

  .rs__input {
    display: block;
    height: 16px;
    justify-self: center;
    margin: 0 $spacer 0 2px;
    padding: 0;

    input {
      height: 16px;

      &:focus {
        border: 0;
        box-shadow: none;
      }
    }
  }

  .rs__clear-indicator {
    color: $text-muted;
    padding-bottom: 0;
    padding-top: 0;
  }

  .rs__loading-indicator {
    display: none;
  }

  .rs__dropdown-indicator {
    color: $border-color;
    padding-bottom: 0;
    padding-top: 0;

    .rs--is-disabled & {
      color: $gray-300;
    }
  }

  .rs__indicator-separator {
    margin: 0;
    background-color: $border-color;
  }

  .rs__placeholder {
    color: $text-color-muted;
    position: absolute;
    margin-left: 0.3em;
    margin-top: -1px;
  }

  .rs__value-container {
    min-height: 33px;
    padding: 2px 10px;
  }

  .rs__value-container--is-multi {
    padding: 2px 7px;
    flex-wrap: nowrap;
    max-width: calc(100% - 37px);
    overflow-x: auto;
  }

  .rs__single-value {
    display: block;
    margin: 0 2px 0 0;
    top: auto;
    transform: none;
  }
}

div.rs__control,
div.inlineRs__control {
  .rs__multi-value,
  .inlineRs__multi-value {
    align-items: center;
    background-color: $blue-500;
    border-radius: $border-radius-sm;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    font-size: inherit;
    height: 23px;
    margin: 2px 4px 2px 0;
    overflow: hidden;

    + [class^='css-']:last-child {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 18px;
      display: flex;
      align-items: center;
    }
  }

  .rs__multi-value__label,
  .inlineRs__multi-value__label {
    color: inherit;
    font-size: 13px;
    font-size: inherit;
    line-height: math.div(15, 13);
    overflow: visible;
    padding: 0 6px;
  }

  .rs__multi-value__remove,
  .inlineRs__multi-value__remove {
    align-items: center;
    background-color: rgba($text-color, 0.2);
    display: flex;
    height: 23px;
    justify-content: center;
    transition: background-color 0.2s;
    width: 23px;

    svg {
      height: 15px;
      width: 15px;
    }

    &:hover {
      background-color: rgba($text-color, 0.4);
      color: inherit;
    }
  }
}

// div for better specificity than the default styles

div.rs__menu,
div.inlineRs__menu {
  @include themify($themes) {
    background-color: themed('color-background');
  }
  border: 0;
  box-shadow: none;
  margin: -1px 0 0;
  padding: 0;
  z-index: 3;

  .rs__menu-list,
  .inlineRs__menu-list {
    border: 1px solid $border-color;
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
    margin: 0;
    padding: 0;
  }

  .rs__menu-list {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .inlineRs__menu-list {
    border-radius: $border-radius;
  }

  .rs__option,
  .inlineRs__option {
    border: 0;
    color: $text-color;
    cursor: pointer;
    font-size: inherit;

    &:hover,
    &--is-focused {
      background-color: $blue-50;
      color: $text-color;
    }

    &--is-selected {
      background-color: $blue-50;
    }
  }

  .rs__no-options-message,
  .inlineRs__no-options-message {
    background-color: $background-gray-light-1;
    color: $text-color-muted;
    cursor: pointer;
    font-size: inherit;
    padding: 20px;
  }
}

div.inlineRs__menu .inlineRs__option {
  font-size: clamp(12px, 1em, $input-font-size);
}
