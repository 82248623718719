@use 'sass:math';

$input-icon-width: 42px;
$input-with-icon-padding: $input-icon-width + 2px; // 1px border on each side

::-webkit-search-cancel-button {
  appearance: none;
  background-image: url('./../img/times-circle-solid.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  display: block;
  height: 16px;
  opacity: 1;
  width: 16px;
}

select {

  &:invalid {
    color: #999;
  }

  option[value=''] {
    color: #999;
  }
}

input {
  @extend .form-control;
}

input,
.form-control {
  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    line-height: math.div(20, 14);
  }

  &[readonly]:not([disabled]) {
    background: $input-bg; // Also removes validation checkmark, useful for readonly inputs
    border-color: $input-border-color;
  }

  &[type='text'] {
    text-overflow: ellipsis;
  }

  &:focus {
    @include focus-shadow($success);
  }

  &.is-valid {
    padding-right: $input-with-icon-padding;

    &:focus {
      @include focus-shadow($success);
    }
  }

  &.is-invalid {
    background-size: auto;
    background-image: url('./../img/error-icon.svg');

    &:focus {
      @include focus-shadow($danger);
    }
  }
}

select,
select.form-control {
  appearance: none;
  background-image: url('./../img/select-input-icon.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  padding: $input-btn-padding-y 2rem $input-btn-padding-y 0.65rem;
}

select:focus {
  @include focus-shadow($success);
}

.form-control.underline-input {
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid $text-color;
  display: inline-block;
  font-size: inherit;
  height: auto;
  line-height: inherit;
  min-width: 0;
  padding: 0 1.5em 0 0;
  width: auto;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;

  + .fa-chevron-down {
    transform: translateX(-1em);
  }
}

form {
  .horizontal {
    label,
    .label {
      @media screen and (min-width: $bp-md) {
        text-align: right;
      }
    }

    .input-container {
      @media screen and (min-width: $bp-md) {
        padding: 9px 0;
      }
    }

    &.toggle-left {
      label,
      .label {
        text-align: left;
      }
    }
  }

  input,
  textarea,
  .react-select .select__control {
    &.input-icon-left {
      border-radius: $border-radius-sm;
      padding-left: $input-with-icon-padding;
    }

    &.input-icon-right,
    &.form-control:not(:last-child),
    &.custom-select:not(:last-child) {
      padding-right: $input-with-icon-padding;
    }

    & + .tooltip-container {
      display: inline-block;
      position: absolute;
      right: 0;
      height: 100%;
      width: $input-icon-width;
    }

    &:placeholder {
      color: $text-color-muted;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $green;
    }
  }

  textarea {
    min-height: 85px;
    resize: vertical;

    &.form-control::-webkit-scrollbar {
      box-shadow: -1px 1px 7px rgba(black, 0.15) inset;
      height: initial;
      padding: initial;
      width: initial;
    }

    &.form-control::-webkit-scrollbar-thumb {
      border-radius: initial;
      background-color: rgba(black, 0.2);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    height: auto;
  }

  .action-group {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 20px;
  }

  .input-group-append,
  .input-group-prepend {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 5px 10px;
    position: absolute;

    &.btn-icon {
      height: 100%;
    }
  }

  .input-group-prepend {
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: 0;
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: 0;
    left: 0;
  }

  .input-group-append {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius-sm;
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius-sm;
    right: 0;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      margin-left: 80px;
      padding-left: 10px;
      width: calc(100% - 80px);
    }

    .form__form-group-label {
      width: 80px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
      }
    }

    .Select-placeholder,
    .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {
      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $blue;
      }

      .Select-value-label {
        @include themify($themes) {
          color: themed('cardText') !important;
        }
      }

      .Select-value-icon {
        border-color: $blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed('colorBorder');
        background: themed('cardColorHover');
      }
    }

    @keyframes open {
      0% {
        max-height: 0;
      }

      100% {
        max-height: 200px;
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('cardColorHover');
        color: themed('cardText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('cardColorHover');
        }
      }
    }

    &.is-focused,
    &.is-focused:not(.is-open) {
      .Select-control {
        border-color: $green;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
}

.form__form-group {
  @include themify($themes) {
    color: themed('cardText');
  }
  padding-bottom: 12px;
  padding-top: 7px;
  position: relative;
  width: 100%;

  @media screen and (min-width: $bp-md) {
    padding-bottom: 9px;
    padding-top: 9px;

    .align-items-top .form__form-group-label {
      padding-top: 16px;
    }

    &.optional .align-items-top .form__form-group-label {
      padding-top: 7px;
    }
  }

  &.stacked {
    padding-bottom: 0;
  }

  &.toggle-container {
    padding-bottom: 7px;
  }

  &.borderless,
  &:last-of-type {
    border-bottom: 0;
  }
}

.toggle-container {
  align-items: center;
  display: flex;

  .form-row {
    flex: 100%;
  }
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
}

.form__form-group-label {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-top: 6px;

  @media screen and (min-width: $bp-md) {
    padding-bottom: 9px;
    padding-top: 9px;
  }

  @include themify($themes) {
    color: themed('cardText');
  }

  .form__form-group-help-text,
  .form__form-group-optional-flag {
    color: $text-color-muted;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $green;
    border: 1px solid $green;

    svg {
      fill: #FFF;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('cardText');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  height: 32px;
  padding: 6px;

  .load-icon-wrap {
    font-size: inherit;
    line-height: 1;

    svg {
      width: 1.1em;
    }
  }

  &:not(a, button) {
    pointer-events: none;
  }

  &:not(.btn) {
    @include themify($themes) {
      background: themed('cardBackground');
      border: $input-border-width solid $input-border-color;
    }
  }

  svg {
    fill: darken($border-color, 15%);
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #AD4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;

  &.input-prepend {
    left: 36px;
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.radio-button-container {
  .input-container {
    float: left;
  }
}

// Fix validation icon position

.was-validated .form-control:valid,
.was-validated input:valid,
.form-control.is-valid,
input.is-valid,
.was-validated .form-control:invalid,
.was-validated input:invalid,
.form-control.is-invalid,
input.is-invalid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: right 10px top 7px;

  &.input-icon-right {
    background-position: right calc(10px + $input-with-icon-padding) top 8px;
    // Limiting width of the input to prevent the icon from overlapping the text
    padding-right: ($input-with-icon-padding * 2);
  }
}

.content-container.highlight-actions {
  border-radius: 17px 17px 17px 16px; // Prevents white border
  overflow: visible;

  .page-header {
    border-radius: 16px 16px 0 0;
  }

  .page-contents {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    > [class*='col'],
    > .form > [class*='col'] {
      padding-bottom: 12px;
      padding-top: 12px;

      @media screen and (min-width: $bp-md) {
        padding-bottom: 18px;
        padding-top: 18px;
      }

      @media screen and (min-width: $bp-xl) {
        padding-bottom: 24px;
        padding-top: 24px;
      }
    }

    > .form {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;

      @media screen and (max-width: ($bp-lg - 1px)) {
        align-content: flex-start;
      }
    }
  }

  .actions-container {
    background-color: white;
    border-radius: 0 0 16px;
    z-index: 2;

    @media screen and (min-width: $bp-lg) {
      max-width: 438px;
    }

    > .col > .form__form-group:first-child {
      padding-top: 0;

      > .form-row.align-items-top:first-child .form__form-group-label {
        padding-top: 0;
      }
    }
  }
}

.help-text {
  color: $gray-400;
  transition: margin-bottom 0.3s ease-in-out;
  will-change: margin-bottom;
}

.new-password-field-container {
  position: relative;
  z-index: 2;
}

// Move me
.story-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .storyTitleWrapper {
    margin-top: -3px;
  }

  .storyTitle {
    &:disabled {
      opacity: 0.5;
    }
  }

  .story-editor-container {
    flex: 1;
  }

  .fieldset .legend {
    margin-bottom: 0;
  }
}

.story-actions-container {
  position: relative;
  z-index: 11 !important; // Higher than .loading-container in the thumbnails
}

// This will be added to the SDK img component so can be removed later
.crop-img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}

.inline-date-field {
  .form-control.is-invalid {
    padding-right: $input-with-icon-padding;
  }
}

.form-actions {
  margin-bottom: 0;
  position: sticky;
  top: 0;
  transition: margin-bottom 0.2s ease-in-out;

  .uploader-visible & {
    margin-bottom: 28px;
  }

  .tab-content .tab-pane {
    padding-top: 20px;
  }

  .form-group {
    @include gutter-sizing(['margin-bottom', 'padding-bottom']);
    border-bottom: 1px solid $border-color-light;
  }

  .form__form-group:first-child:not(.toggle-container) label {
    padding-top: 0;
  }

  .form__form-group {
    border: 0;
    padding-top: 0;
  }

  .action-buttons {
    margin-top: 0;

    &:first-child {
      padding-top: 10px;
    }

    &.buttons-group {
      margin-top: 20px;
      padding-top: 0;
    }

    > * {
      @include gutter-sizing('margin-bottom');
    }
  }

  .form-action-preview-container {
    margin: 0 auto;
    max-width: 40vh;
  }

  .settings-tab {
    display: flex;
    flex-direction: column;
    align-items: center;

    .file-preview {
      margin-left: auto;
      margin-right: auto;
    }

    .file-upload-container {
      margin-top: 20px;
    }
  }

  .story-icon-input,
  .story-thumbnail-input {
    padding-bottom: 12px;

    .dropzone {
      margin: auto;
    }

    .open-file-picker {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .story-icon-input + .story-thumbnail-input {
    border-top: 1px solid $border-color-light;
    margin-top: 20px;
    padding-top: 20px;
  }

  .sharing-options {
    border-top: 1px solid $border-color-light;
    margin: 20px 0;
    padding-top: 20px;
  }

  .deeplink-container {
    color: $text-color-muted;
    margin-top: 20px;
    text-align: center;

    .trigger {
      margin-bottom: 4px;
      text-align: center;
      text-decoration: underline;

      .svg-inline--fa {
        margin-right: 0.4em;
      }
    }
  }

  .schedule-container {
    @include gutter-sizing([ 'margin-top', 'margin-bottom' ]);
  }

  .chip-group {
    margin-top: -10px;
  }

  .fieldset {
    margin-top: 24px;
  }
}
