@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.SelectField {
  .hasErrors {
    div.rs__control {
      border-color: $red;
    }

    div.rs__control--is-focused {
      box-shadow: none;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $border-radius;
        outline: 2px solid rgb(210 71 71 / 50%);
        margin: -2px;
      }
    }
  }

  &.inline {
    display: inline-block;
  }
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  left: 15px;
}

.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  option {
    @include themify($themes) {
      background-color: themed('color-background');
    }
  }
}

div.rs__control {
  border-color: $input-border-color;
  border-radius: $border-radius;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: $input-font-size;
  height: 35px;
  min-height: 30px;
  min-width: 122px;
  width: 100%;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    min-height: 30px;
  }

  &:hover {
    border-color: $input-border-color;
  }

  &--is-focused {
    box-shadow: none;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: $border-radius;
      outline: 2px solid rgba(29, 181, 108, 0.5);
      margin: -2px;
    }
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0.1px;
    border-bottom-right-radius: 0.1px;
  }

  .rs__input {
    display: block;
    height: 16px;
    justify-self: center;
    margin: 0 $spacer 0 2px;
    padding: 0;

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  .rs__clear-indicator {
    color: $text-muted;
    padding-bottom: 0;
    padding-top: 0;
  }

  .rs__loading-indicator {
    display: none;
  }

  .rs__dropdown-indicator {
    color: $black;
    padding-bottom: 0;
    padding-top: 0;
  }

  .rs__indicator-separator {
    margin: 0;
    background-color: $border-color;
    display: none;
  }

  .rs__placeholder {
    color: $text-color-muted;
    position: absolute;
    margin-left: 0.3em;
    margin-top: -1px;
  }

  .rs__value-container {
    min-height: 33px;
    padding: 2px 10px;
  }

  .rs__value-container--is-multi {
    padding: 2px 7px;
    flex-wrap: nowrap;
    max-width: calc(100% - 37px);
    overflow-x: auto;
  }

  .rs__single-value {
    display: block;
    margin: 0 2px 0 0;
    top: auto;
    transform: none;
  }

  .rs__multi-value {
    align-items: center;
    background-color: $blue-500;
    border-radius: $border-radius-sm;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    font-size: inherit;
    height: 23px;
    margin: 2px 4px 2px 0;
    overflow: hidden;

    + [class^='css-']:last-child {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 18px;
      display: flex;
      align-items: center;
    }
  }

  .rs__multi-value__label {
    color: inherit;
    font-size: 13px;
    font-size: inherit;
    line-height: calc(15 / 13);
    overflow: visible;
    padding: 0 6px;
  }

  .rs__multi-value__remove {
    align-items: center;
    background-color: rgba($text-color, 0.2);
    display: flex;
    height: 23px;
    justify-content: center;
    transition: background-color 0.2s;
    width: 23px;

    svg {
      height: 15px;
      width: 15px;
    }

    &:hover {
      background-color: rgba($text-color, 0.4);
      color: inherit;
    }
  }
}

div.inlineRs__control {
  .value-container--is-custom input {
    all: unset;
    font-size: inherit;
    color: inherit;
    cursor: text;
  }
}

div.rs__menu,
div.inlineRs__menu {
  @include themify($themes) {
    background-color: themed('color-background');
  }
  border: 0;
  box-shadow: none;
  margin: -1px 0 0;
  padding: 0;
  z-index: 3;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  .rs__menu-list {
    border: 1px solid $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
    margin: 0;
    padding: 0;
  }

  .rs__option {
    border: 0;
    color: $text-color;
    cursor: pointer;
    font-size: inherit;
    word-break: break-word;

    &:hover,
    &--is-focused {
      background-color: $blue-50;
      color: $text-color;
    }

    &--is-selected {
      background-color: $blue-100;
    }

    &--is-disabled {
      opacity: 0.5;
      cursor: initial;

      &:hover {
        background-color: $white;
      }
    }
  }

  .rs__no-options-message {
    background-color: $background-gray-light-1;
    color: $text-color-muted;
    cursor: pointer;
    font-size: inherit;
    padding: 20px;
  }
}

div.inlineRs__menu {
  min-width: 100%;
}

// All Custom Styles for Alternative Select Field look are located in SelectFieldCustomStyles.tsx
.alternative {
  input {
    height: inherit;
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.withCheckboxes {
  div.rs__menu,
  div.inlineRs__menu {
    .rs__option,
    .inlineRs__option {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      &:before {
        display: inline-block;
        content: '';
        width: 16px;
        height: 16px;
        background-color: $white;
        background-size: cover;
        border-radius: 4px;
        border: 1px solid $gray-300;
      }

      &--is-selected {
        background-color: inherit;

        &:before {
          background-color: $blue-500;
          border-color: $blue-500;
          background-image: url('./img/checkmark.svg');
        }
      }
    }
  }
}

.menuPlacementTop {
  .rs__control:not(:last-child) {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rs__menu {
    .rs__menu-list {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
}

.readOnly {
  div.rs__control {
    background-color: $gray-100;
    cursor: not-allowed;

    .rs__single-value {
      color: $black;
    }
  }
}
